import React, { useEffect, useState } from "react";
import MaterialTable, { MTableBodyRow } from "material-table";
import { StatsCard, Tabs as SimpleTabs, Tab as SimpleTab, Avatar, Badge } from 'tabler-react';

import { Grid, Snackbar, Checkbox, Card, CardMedia, CardContent, Typography, CardActionArea, CardActions, TextField, Button, Paper, FormControlLabel, FormGroup, ButtonGroup } from '@material-ui/core';

import { useRequest, get, post, deleteReq } from '../../utils/request';
import ContentLoader from "../ContentLoader"

import DeleteIcon from '@material-ui/icons/Delete';
import { Box } from '@material-ui/core'

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Settings = (props) => {

    // design of the GET http request for querying leads 
    let requestOpts = { "host": "https://bankier-leads-backend2.herokuapp.com" }
    // requestOpts = { "host": "http://localhost:8080" }
    const [loading, error, queryData, makeRequest] = useRequest({ loading: true });
    const [followups, setFollowups] = useState([])

    const [addFollowupTitle, setAddFollowupTitle] = useState("")
    const [addFollowupDays, setAddFollowupDays] = useState("")

    const [openAlert, setOpenAlert] = React.useState(false);
    const handleClick = () => {
        setOpenAlert(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenAlert(false);
    };

    function isNormalInteger(str) {
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    }




    // Update Backend and Local state of followups
    function updateFollowupTemplate() {
        post("/followups", {
            followupTemplate: followups,
        });
    }
    function deleteFollowup(idxToRemove) {
        setFollowups(followups.filter(function (item, idx, arr) { return idx !== idxToRemove }));
    }

    function addFollowup() {
        if (addFollowupTitle.length > 0 && addFollowupDays.length > 0 && isNormalInteger(addFollowupDays)) {
            let updateFollowups = [...followups, { title: addFollowupTitle, days: addFollowupDays, isComplete: false }];
            console.log("added followup");
            updateFollowups.sort((a, b) => parseInt(a.days) - parseInt(b.days));
            setFollowups(updateFollowups);
            setAddFollowupTitle("");
            setAddFollowupDays("");
        }
        else {
            handleClick();
        }
    }

    const getFollowupTemplate = async () => {
        const res = await makeRequest('/followups', "GET", null, requestOpts);
        setFollowups(res.followupTemplate);

    }
    // end followups management


    useEffect(() => {
        getFollowupTemplate();
    }, [])
    return (
        <>
            <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    Error please try again!
                </Alert>
            </Snackbar>

            <Grid container sm={5}>
                <Grid item sm={12}>
                    <Paper>
                        <hr />
                        <h3>Manage Followup Gameplan</h3>
                        <Paper>
                            {
                                (followups !== null) && followups.map((followup, idx) => {
                                    return (
                                        <Grid container sm={12}>
                                            <Grid item sm={8}>
                                                <TextField fullWidth placeholder="Followup Title" variant="outlined" value={followup.title}
                                                    onChange={
                                                        (e) => {
                                                            // 1. Make a shallow copy of the items
                                                            let items = [...followups];
                                                            // 2. Make a shallow copy of the item you want to mutate
                                                            let item = {
                                                                ...items[idx],
                                                                title: e.target.value
                                                            };
                                                            // 3. Replace the property you're intested in
                                                            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                            items[idx] = item;
                                                            // 5. Set the state to our new copy
                                                            setFollowups(items);
                                                        }
                                                    } />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <TextField fullWidth placeholder="Days" variant="outlined" value={followup.days}
                                                    type="number"
                                                    inputProps={{ className: 'digitsOnly' }}
                                                    onChange={
                                                        (e) => {
                                                            // 1. Make a shallow copy of the items
                                                            let items = [...followups];
                                                            // 2. Make a shallow copy of the item you want to mutate
                                                            let item = {
                                                                ...items[idx],
                                                                days: e.target.value
                                                            };
                                                            // 3. Replace the property you're intested in
                                                            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
                                                            items[idx] = item;
                                                            items.sort((a, b) => parseInt(a.days) - parseInt(b.days));

                                                            // 5. Set the state to our new copy
                                                            setFollowups(items);
                                                        }
                                                    } />
                                            </Grid>
                                            <Grid item sm={1} style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }} >
                                                <Paper onClick={() => {
                                                    deleteFollowup(idx);
                                                }} >
                                                    <DeleteIcon />
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }

                        </Paper>

                        <Grid container sm={12} style={{ display: 'flex', alignItems: "center" }}>
                            <Grid item sm={6}>
                                <TextField fullWidth placeholder="Followup Title" variant="outlined" value={addFollowupTitle} onChange={e => setAddFollowupTitle(e.target.value)} />
                            </Grid>
                            <Grid item sm={3}>
                                <TextField fullWidth placeholder="Days" variant="outlined" value={addFollowupDays} onChange={e => setAddFollowupDays(e.target.value)} />
                            </Grid>
                            <Grid item sm={3} style={{
                                display: 'flex',
                                alignItems: 'center',
                            }} >

                                <Button variant="contained" color="primary" size="large" onClick={() => addFollowup()}>+ Add</Button>
                            </Grid>
                        </Grid>

                        <Button variant="contained" color="secondary" size="large" fullWidth onClick={() => updateFollowupTemplate()}>Save</Button>


                    </Paper>
                </Grid>
            </Grid>

        </>
    );
}

export default Settings;