import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import 'tabler-react/dist/Tabler.css';
import { Loader } from 'tabler-react';
import { BrowserRouter as Router } from 'react-router-dom';

import ProtectedNavbar from './components/Navbar/ProtectedNavbar';

import ResetPassword from './components/Auth/ResetPassword';

// Import Routes
import AuthRoutes from './routes/AuthRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';


import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet


function App() {
  const [authIsUpToDate, setAuthIsUpToDate] = useState(true);
  const [user, setUser] = useState(true);

  // useEffect(() => {
  //   firebase.auth.onAuthStateChanged((u) => {
  //     setUser(u);
  //     setAuthIsUpToDate(true);
  //   });
  // }, []);

  return (
    <>
      {!authIsUpToDate ? (
        <div className="center-page">
          <Loader />
        </div>
      ) : (
          <div className="App">
            <Router>{user ? <ProtectedRoutes /> : <AuthRoutes />}</Router>
          </div>
        )}
      <Feedback projectId="5f75fb7c46decc000448298b" primaryColor="#3f51b5" />;
    </>
  );
}

export default App;
