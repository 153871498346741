import React from 'react';
import { Loader } from 'tabler-react';

const ContentLoader = (props) => {
    const { error, loading, children } = props;

    if (loading) {
        return <Loader />;
    }

    if (error) {
        return <h2>Error: {error}</h2>;
    }

    return <>{children}</>;
};

export default ContentLoader;
