import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { SignUp, SignIn, ResetPassword } from '../components/Auth';
import { ProtectedNavbar } from "../components/Navbar"
import Leads from '../components/Leads/Leads';
import Settings from '../components/Settings/Settings';
import Sample from '../components/Leads/Sample';

const ProtectedRoutes = () => {
  return (
    <>
      <ProtectedNavbar />
      <Switch>
        <Route path="/" exact>
          <Leads />
        </Route>
        <Route path="/sample" exact>
          <Sample />
        </Route>
        <Route path="/settings" exact>
          <Settings />
        </Route>
        <Route path="/signout">
          Signout
      </Route>
      </Switch>
    </>
  );
};

export default ProtectedRoutes;
