import React, { useEffect, useRef, useState } from "react";
import MaterialTable, { MTableBodyRow } from "material-table";
import { StatsCard, Card, Tabs as SimpleTabs, Tab as SimpleTab, Avatar, Badge } from 'tabler-react';

import { Grid, Checkbox, TextField, Button, Paper, FormControlLabel, FormGroup, ButtonGroup } from '@material-ui/core';


import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

// import for Tab bar
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import "./Jobs.css"
import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';


import { useRequest, get, post, deleteReq } from '../../utils/request';
import ContentLoader from "../ContentLoader"
import moment from "moment"


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },

    // scrollmenu: {
    //     backgroundColor: theme.palette.background.paper,
    //     overflow: auto,
    //     whiteSpace: nowrap,
    // },
}));

const Sample = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [data, setData] = React.useState([
        {
            id: 1,
            name: "Amulya Parmar TYG",
            email: "parmar.amulya@gmail.com",
            leadType: [0],
            agent: 0,
            number: "123321123",
            notes: "needed 3bed",
            tourBookedFor: " 406 E. Green St, 3 Bedroom",
            tourBookedOn: " October 15, 2020 3 PM",
            questionAsked: "",
            leadStatus: "7 Day Followup Needed",
            followups: [
                {
                    title: "Same Day Followup",
                    days: 0,
                    isComplete: true,
                },
                {
                    title: "3 Day Followup",
                    days: 3,
                    isComplete: false,
                },
                {
                    title: "5 Day Followup",
                    days: 5,
                    isComplete: false,
                },
                {
                    title: "7 Day Followup",
                    days: 7,
                    isComplete: false,
                }
            ],
            hasApplied: false,
            notInterested: false,

            updated_on: "25 September 2020",
            created_on: "Mon, 01 Oct 2020 06:35:56 GMT",
        },
    ])



    const agentList = [
        //Kierra, Ashley, Ron Bufkin, Savannah Meneley, Ryan Marde
        { name: 'Ashley Matheny', value: 0 },
        { name: 'Kierra Morris', value: 1 },
        { name: 'Ron Bufkin', value: 2 },
        { name: 'Savannah Meneley', value: 3 },
        { name: 'Ryan Marde', value: 4 },
        { name: 'Agent #1', value: 5 },
        { name: 'Agent #2', value: 6 },
        { name: 'Agent #3', value: 7 },
        { name: 'Agent #4', value: 8 },
        { name: 'Agent #5', value: 9 },
    ];

    const leadType = [
        { name: 'Booked Tour', value: 0 },
        { name: 'Ask a Question', value: 1 },
        { name: 'In-Person', value: 2 },
        { name: 'Other', value: 3 },
        { name: 'Applied for Lease', value: 4 },
        { name: 'Not Interested', value: 5 },
    ];

    const tableRef = React.createRef({
        onQueryChange: () => {
        }
    });




    useEffect(() => {
        // getLeads();
    }, [])

    return (
        <div className={classes.root}>

            {/* <center>
                <Grid container spacing={1}>
                    <Grid container item xs={4} spacing={3}>
                        <StatsCard layout={1} movement={+10} total={leadLength} label="Leads" />
                    </Grid>
                    <Grid container item xs={4} spacing={3}>
                        <StatsCard layout={1} movement={-3} total={followupsNeeded} label="Followups need to take" />
                    </Grid>
                    <Grid container item xs={4} spacing={3}>
                        <StatsCard layout={1} movement={-3} total={leaseCount} label="Leases Signed" />
                    </Grid>
                </Grid>
            </center> */}
            {/* 
            <Autocomplete
                id="combo-box-demo"
                options={agentList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Agent" variant="outlined" />}
            />
            <br /> */}


            <MaterialTable
                //https://stackoverflow.com/questions/60009133/how-to-get-reactjs-material-table-search-results-count
                tableRef={tableRef}
                // onSearchChange={() => {
                //     console.log(tableRef.current.state.data);
                //     updateLeadStats(tableRef.current.state.data)
                // }}
                // icons={tableIcons}
                // options={{
                //     headerStyle: {
                //         backgroundColor: '#01579b',
                //         color: '#FFF'
                //     },
                //     rowStyle: {
                //         backgroundColor: '#EEE',
                //     }
                // }}
                columns={[
                    { title: "Name", field: "name" },
                    { title: "Email", field: "email" },
                    // {
                    //     title: "Agent", field: "agent", render: rowData => {
                    //         return (
                    //             <>

                    //                 <Grid container spacing={3}>
                    //                     <Grid item sm={3}>
                    //                         <Avatar>{rowData.agent !== null && agentList[rowData.agent].name.split(" ").map((n) => n[0]).join("").split(0, 2)}</Avatar>
                    //                     </Grid>
                    //                     <Grid item sm={9} style={{ "margin": "auto" }}>{(rowData.agent !== null) && agentList[rowData.agent].name}</Grid>
                    //                 </Grid>

                    //             </>
                    //         );
                    //     },
                    //     customFilterAndSearch: (term, localRowData) => {
                    //         return agentList[localRowData.agent].name.toLowerCase().indexOf(term.toLowerCase()) !== -1
                    //     }
                    // },
                    // {
                    //     title: "Property Tour / Question",
                    //     field: "surname",
                    //     cellStyle: {
                    //         maxWidth: 20
                    //     },
                    //     render: rowData => {
                    //         var questionAsked = "questionAsked" in rowData && rowData.questionAsked.length > 1
                    //         var tourBooked = "tourBookedFor" in rowData && "tourBookedOn" in rowData && rowData.tourBookedFor.length > 1 && rowData.tourBookedOn.length > 1
                    //         return (
                    //             <>
                    //                 {questionAsked ?
                    //                     <span> Question asked: {rowData.questionAsked} </span>
                    //                     :
                    //                     <span></span>
                    //                 }
                    //                 {questionAsked && tourBooked && <span> | </span>}
                    //                 {tourBooked ?
                    //                     <span style={{ width: "100px" }}> Booked Tour For: "{rowData.tourBookedFor}" on {rowData.tourBookedOn}</span>
                    //                     :
                    //                     <span></span>
                    //                 }
                    //             </>
                    //         )
                    //     }

                    // },

                    // {
                    //     title: "Date Created", field: "created_on", render: rowData => {
                    //         let createTimestamp = Date.parse(rowData.created_on);
                    //         // createTimestamp.toLocaleFormat('%d-%b-%Y');

                    //         return <span>{moment(createTimestamp).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    //     }
                    // },
                    // {
                    //     title: "Lead Type",
                    //     field: "leadType",
                    //     render: rowData => {
                    //         return (
                    //             <>
                    //                 {(rowData["leadType"] !== null) && rowData.leadType.map((item, idx) => {
                    //                     return <Badge color="primary"> {leadType[item].name}  &nbsp;</Badge>
                    //                 })}
                    //             </>
                    //         )
                    //     },
                    //     customFilterAndSearch: (term, localRowData) => {
                    //         return localRowData.leadType.some((leadIdx) => {
                    //             return leadType[leadIdx].name.toLowerCase().indexOf(term.toLowerCase()) !== -1
                    //         }
                    //         )


                    //     }
                    // },
                    // // {
                    // //     field: 'isLeased',
                    // //     title: 'Leased',
                    // //     render: rowData => {
                    // //         return (
                    // //             <Checkbox checked={rowData.isLeased} onClick={rowData.isChecked} />
                    // //         );
                    // //     }
                    // // },
                    // {
                    //     title: "Lead Status", field: "leadStatus", render: rowData => {
                    //         var hasApplied = ("hasApplied" in rowData && rowData.hasApplied === true)
                    //         var notInterested = ("notInterested" in rowData && rowData.notInterested === true)
                    //         return (
                    //             <>
                    //                 {hasApplied && <Badge color="success" className="mr-1">
                    //                     Applied
                    //             </Badge>}
                    //                 {(notInterested && !hasApplied) && <Badge color="secondary" className="mr-1">
                    //                     Not Interested
                    //             </Badge>}
                    //                 {(!hasApplied && !notInterested) && ("followups" in rowData) &&
                    //                     checkIfLeadHasFollowups(rowData).map((followup) => {
                    //                         return (
                    //                             <>
                    //                                 < Badge color="danger" className="mr-1">
                    //                                     {followup.title}
                    //                                 </Badge>
                    //                             </>
                    //                         )
                    //                     })

                    //                 }


                    //             </>
                    //         );
                    //     }
                    // },
                ]}
                // components={{
                //     Row: props => (
                //         <MTableBodyRow
                //             {...props}
                //         // onDoubleClick={e => {
                //         //     console.log(props.actions);
                //         //     props.actions[1]().onClick(e, props.data);
                //         // }}
                //         />
                //     )
                // }}
                // editable={{
                //     onRowAdd: newData =>
                //         new Promise((resolve, reject) => {
                //             setTimeout(() => {
                //                 /* setData([...data, newData]); */
                //                 addLead({ "name": newData.name, "email": newData.email })
                //                 resolve();
                //             }, 1000);
                //         }),
                //     onRowUpdate: (newData, oldData) =>
                //         new Promise((resolve, reject) => {
                //             resolve();
                //         }),
                //     onRowDelete: oldData =>
                //         new Promise((resolve, reject) => {
                //             resolve();
                //         })
                // }}
                data={data}
            // title="Bankier Leads Tracker"
            // detailPanel={rowData => {
            //     return (
            //         <span>test</span>
            //     )
            // }}
            // onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />

            <Button onClick={() => {
                console.log('tableRef', tableRef)
                tableRef.current.onQueryChange();
            }

            }>
                trigger update
                </Button>

            <br />
            <br />
            <br />
            <br />
            <br />
        </div >
    );
}

export default Sample;