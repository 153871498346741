import React, { useEffect, useRef, useState } from "react";
import MaterialTable, { MTableBodyRow, MTableBody } from "material-table";
import { StatsCard, Tabs as SimpleTabs, Tab as SimpleTab, Avatar, Badge, StampCard } from 'tabler-react';

import { Grid, Checkbox, TextField, Button, Paper, FormControlLabel, FormGroup, ButtonGroup, Input } from '@material-ui/core';


import { forwardRef } from 'react';

import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

// import for Tab bar
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import "./Jobs.css"
import CallIcon from '@material-ui/icons/Call';
import MessageIcon from '@material-ui/icons/Message';
import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';


import { useRequest, get, post, deleteReq } from '../../utils/request';
import ContentLoader from "../ContentLoader"
import moment from "moment"



import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} keyboard-shortcut={"add-icon-handler"} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

    },

    // scrollmenu: {
    //     backgroundColor: theme.palette.background.paper,
    //     overflow: auto,
    //     whiteSpace: nowrap,
    // },
}));

const GeneralSection = ({ rowData, setRowData, agentList, leadType, postLead, deleteLead, updateRowInRowData, tableRef }) => {
    const classes = useStyles();


    Date.prototype.yyyymmdd = function () {
        var mm = this.getMonth() + 1; // getMonth() is zero-based
        var dd = this.getDate();

        return [
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd,
            this.getFullYear(),
        ].join('-');
    };


    function addFollowupMessageToNotes(followupNote = "") {
        var d = moment(new Date());

        var updateNote = {
            ...rowData,
            ["notes"]: followupNote + " note [" + d.format('MMMM Do YYYY, h:mm:ss A') + " ] : \n\n\n\n---\n " + rowData["notes"],
        }
        setRowData(updateNote)
        updateRowInRowData(updateNote)
    }

    const leadTypeRef = useRef(null)
    useEffect(() => {
        console.log("agentList Inner: ", agentList);
    }, [])

    const [currentLeadTypes, setCurrentLeadTypes] = useState(rowData.leadType)

    let createTimestamp = moment(Date.parse(rowData.created_on));
    let updateTimestamp = moment(Date.parse(rowData.updated_on));
    // createTimestamp.toLocaleFormat('%d-%b-%Y');
    let todayTimestamp = moment(Date.now());

    return (
        <div style={{ width: "100%" }}>
            <div style={{ color: "grey" }}> <Badge color="secondary"> Date Created: {createTimestamp.format('MMMM Do YYYY, h:mm:ss A')} </Badge>  | <Badge color="secondary"> Last Updated: {updateTimestamp.format('MMMM Do YYYY, h:mm:ss a')}</Badge></div>
            <br />

            <Grid container >
                <Grid item sm={2}>
                    <TextField id="standard-basic"
                        fullWidth label="Name"
                        variant="outlined"
                        filled
                        value={rowData.name}
                        onChange={(event) => {
                            var updateName = {
                                ...rowData,
                                ["name"]: event.target.value,
                            }
                            setRowData(updateName);
                            updateRowInRowData(updateName);
                        }}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField id="standard-basic"
                        fullWidth label="Email"
                        variant="outlined"
                        filled
                        value={rowData.email}
                        onChange={(event) => {
                            var updateEmail = {
                                ...rowData,
                                ["email"]: event.target.value,
                            }
                            setRowData(updateEmail);
                            updateRowInRowData(updateEmail);

                        }}
                    />
                </Grid>
                <Grid item sm={2}>
                    <TextField id="standard-basic"
                        fullWidth label="Phone Number"
                        variant="outlined" filled
                        value={rowData.number}
                        onChange={(event) => {
                            var updatePhone = {
                                ...rowData,
                                ["number"]: event.target.value,
                            }
                            setRowData(updatePhone);
                            updateRowInRowData(updatePhone);

                        }}
                    />
                </Grid>


                <Grid item sm={3}>
                    {/* <Autocomplete
                        multiple
                        id="lead-type-status"
                        defaultValue={leadType[0]}
                        options={leadType}
                        getOptionLabel={(option) => option.name}
                        freeSolo
                        renderInput={(params) => <TextField {...params} label="Lead Type" variant="outlined" />}
                    /> */}
                    <Autocomplete
                        multiple
                        id="tags-standard"
                        options={leadType}
                        getOptionLabel={(option) => option.name}
                        value={rowData.leadType.map((typeIdx, idx) => {
                            return leadType[typeIdx];
                        })}
                        onChange={(event, value) => {

                            var updateLeadType = {
                                ...rowData,
                                ["leadType"]: value.map((leadTypeObj, idx) => {
                                    const index = leadType.findIndex(item => item === leadTypeObj);
                                    return index;
                                }),
                            }

                            setRowData(updateLeadType);
                            updateRowInRowData(updateLeadType);

                        }


                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Lead Type"
                                placeholder="Add Type"
                            />
                        )}
                    />
                </Grid>

                <Grid item sm={3}>
                    <Autocomplete
                        value={agentList[rowData.agent]}
                        id="combo-box-demo"
                        options={agentList}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Agent" variant="outlined" />}
                        // onChange={(e) =>
                        //     setRowData(oldRowData => {
                        //         return (
                        //             {
                        //                 ...oldRowData,
                        //                 ["agent"]: e.target.value,
                        //             }
                        //         )

                        //     })
                        // }
                        onChange={(event, value) => {

                            // var updateLeadType = {
                            //     ...rowData,
                            //     ["leadType"]: value.map((leadTypeObj, idx) => {
                            //         const index = leadType.findIndex(item => item === leadTypeObj);
                            //         return index;
                            //     }),
                            // }

                            // setRowData(updateLeadType);
                            console.log(value)
                            const index = agentList.findIndex(agent => agent === value);

                            var updateAgent = {
                                ...rowData,
                                ["agent"]: index,
                            }

                            setRowData(updateAgent);
                            updateRowInRowData(updateAgent);
                        }}
                    />
                </Grid>


            </Grid>


            <br />



            {/* Same-Day Followup
                3 Day Followup
                7 Day Followup
                14 Day Folllowup
                30 Day Folllowup */}

            <Grid container spacing={3}>
                <Grid item sm={3}>
                    <Paper variant="outlined" elevation={3}>


                        {("followups" in rowData) &&
                            rowData.followups.map((followup, idx) => {
                                if (todayTimestamp.diff(createTimestamp, "days") >= followup.days) {
                                    return (
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={idx}
                                                        color="primary"
                                                        // onClick={
                                                        //     (e) => {
                                                        //         if (e.target.checked === true) {
                                                        //             addFollowupMessageToNotes(followup.title);
                                                        //         }

                                                        //     }
                                                        // }
                                                        onChange={
                                                            (e) => {

                                                                console.log("e target value ", e.target.checked);

                                                                var followups = rowData.followups.slice();
                                                                followups[idx]["isComplete"] = e.target.checked;

                                                                var followupsUpdated = {
                                                                    ...rowData,
                                                                    followups: followups
                                                                }
                                                                setRowData(followupsUpdated);
                                                                updateRowInRowData(followupsUpdated);
                                                                if (e.target.checked === true) {
                                                                    addFollowupMessageToNotes(followup.title);
                                                                }

                                                            }}

                                                        defaultChecked={followup.isComplete}
                                                    />

                                                }
                                                label={followup.title}
                                            />
                                        </FormGroup>

                                    )
                                };
                            })
                        }


                        <Grid container >

                            <Grid item sm={12}>
                                <FormGroup row>

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rowData.notInterested}
                                                name="not-interested"
                                                indeterminate
                                                onChange={(e) => {
                                                    const updateInterest = {
                                                        ...rowData,
                                                        ["notInterested"]: e.target.checked,
                                                    }
                                                    setRowData(updateInterest);
                                                    updateRowInRowData(updateInterest);

                                                }}
                                            />

                                        }
                                        label="Not Interested"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rowData.hasApplied}
                                                name="applied-lease"
                                                onChange={(e) => {
                                                    const updateLease = {
                                                        ...rowData,
                                                        ["hasApplied"]: e.target.checked,
                                                    }
                                                    setRowData(updateLease);
                                                }}

                                            />

                                        }
                                        label="Applied for Lease"
                                    />
                                </FormGroup>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item sm={9}>
                    {(rowData.questionAsked.length > 0 || rowData.leadType.indexOf(1) >= 0) &&
                        <>
                            <TextField
                                id="outlined-multiline"
                                placeholder="- What question did they ask"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                variant="outlined"
                                fullWidth
                                value={rowData.questionAsked}
                                label="Question asked"
                                onChange={e => {

                                    const val = e.target.value;
                                    let updatedRow = { ...rowData, ["questionAsked"]: val };
                                    setRowData(updatedRow)
                                    updateRowInRowData(updatedRow);

                                }
                                }


                            />
                            <br />
                        </>}
                    {(rowData.tourBookedFor.length > 0 || rowData.leadType.indexOf(0) >= 0) && <TextField
                        id="outlined-multiline"

                        rows={2}
                        placeholder="- Where did they book the tour four?"
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        fullWidth
                        value={rowData.tourBookedFor}
                        label="Booked tour for: "
                        onChange={e => {
                            const val = e.target.value;
                            setRowData(oldRow => {
                                return { ...oldRow, ["tourBookedFor"]: val }
                            })
                        }}
                    />

                    }
                    {(rowData.tourBookedOn.length > 0 || rowData.leadType.indexOf(0) >= 0) && <TextField
                        id="outlined-multiline"

                        rows={1}
                        placeholder="When did they book the tour on"
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        fullWidth
                        value={rowData.tourBookedOn}
                        onChange={e => {
                            const val = e.target.value;
                            setRowData(oldRow => {
                                return { ...oldRow, ["tourBookedOn"]: val }
                            })
                        }}
                    />}
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={8}
                        placeholder="- What floor plans are they interested in? How many roommates? Any additional information did they want?"
                        InputLabelProps={{
                            shrink: true
                        }}
                        variant="outlined"
                        fullWidth
                        value={rowData.notes}
                        onChange={e => {

                            const val = e.target.value;
                            let updatedRow = { ...rowData, ["notes"]: val };

                            setRowData(updatedRow)
                            updateRowInRowData(updatedRow);
                        }
                        }

                    />

                    <br />
                </Grid>
            </Grid>

            <br />
            <br />

            <Grid container >
                <Grid item md={9}>
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={<SaveIcon />}
                        fullWidth
                        color="primary"
                        onClick={() => {
                            console.log("rowData: ", rowData)
                            // let createTimestamp = strDate => Date.parse(rowData.created_on);
                            postLead(rowData.id, rowData);
                        }}
                    >
                        Save
            </Button>
                </Grid>
                <Grid item md={3}>
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={<DeleteIcon />}
                        fullWidth
                        color="inherit"
                        onClick={() => {
                            console.log('item to delte', rowData.id, rowData.name);
                            deleteLead(rowData.id);
                        }}
                    >
                        Delete
            </Button>

                </Grid>
            </Grid>
            <br />


            <br />
            <hr />
        </div >

    );
}


const MesssageSection = ({ rowData }) => {
    return (


        <div>

            <Grid container spacing={3}>
                <Grid item sm={3}><TextField id="standard-basic" fullWidth label="Name" variant="outlined" filled value={rowData.name} /></Grid>
                <Grid item sm={3}><TextField id="standard-basic" fullWidth label="Email" variant="outlined" filled value={rowData.email} /></Grid>
                <Grid item sm={2}>
                    <TextField id="standard-basic"
                        fullWidth label="Phone Number"
                        variant="outlined" filled
                        value={rowData.number}
                    /></Grid>
                <Grid item sm={2}>
                    <Button variant="outlined" color="primary">
                        Primary
                    </Button>
                </Grid>

            </Grid>
            <br />
            <div >

                <Grid container spacing={3} style={{ overflow: "auto" }}>




                    <Grid item sm={3}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Tour Check-in</Card.Title>
                                <Card.Options>

                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                Hi {rowData.name}, it’s Ashley from
                                thank you for signing up to
                                tour at 406 E. Green St.
                                Will you be able to make
                                It at 4:15 PM tomorrow?

                    </Card.Body>
                            <Card.Footer>
                                <Button color="primary" size="sm">
                                    Copy
                            </Button>

                            </Card.Footer>
                        </Card>
                    </Grid>


                    <Grid item sm={3}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Are you still interested?</Card.Title>
                                <Card.Options>

                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                Hi, it’s Ashley from thank you for signing up to tour at 406 E. Green St. Will you be able to make It at 4:15 PM tomorrow?

                            </Card.Body>
                            <Card.Footer>
                                <Button color="primary" size="sm">
                                    EMAIL
                            </Button>
                                <Button color="secondary" size="sm" className="ml-2">
                                    SMS
                            </Button>
                            </Card.Footer>
                        </Card>
                    </Grid>

                    <Grid item sm={3}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Perfect for you?</Card.Title>
                                <Card.Options>

                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                Hi, it’s Ashley, I know the floor plan you were looking at just leased out, but I just found one unit at x that just opened up that would be perfect for you.
                    </Card.Body>
                            <Card.Footer>
                                <Button color="primary" size="sm">
                                    Action 1
                            </Button>
                                <Button color="secondary" size="sm" className="ml-2">
                                    Action 2
                            </Button>
                            </Card.Footer>
                        </Card>
                    </Grid>
                    <Grid item sm={3}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Only 2 units left</Card.Title>
                                <Card.Options>

                                </Card.Options>
                            </Card.Header>
                            <Card.Body>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam deleniti
                                fugit incidunt, iste, itaque minima neque pariatur perferendis sed suscipit
                                velit vitae voluptatem. A consequuntur, deserunt eaque error nulla
                                temporibus!
                    </Card.Body>
                            <Card.Footer>
                                <Button color="primary" size="sm">
                                    Action 1
                            </Button>
                                <Button color="secondary" size="sm" className="ml-2">
                                    Action 2
                            </Button>
                            </Card.Footer>
                        </Card>
                    </Grid>

                </Grid>
            </div>


            <TextField
                id="outlined-multiline-static"
                multiline
                rows={8}
                placeholder="Your message here"
                InputLabelProps={{
                    shrink: true
                }}
                variant="outlined"
                fullWidth
                value=""

            />
            <Button color="primary" fullWidth> Send </Button>
        </div>
    );
}





const LeadActionPanel = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [rowData, setRowData] = React.useState(props.rowData);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        console.log("agentList: ", props.agentList);
    }, [])

    return (
        <div className={classes.root}>
            {/* <AppBar position="static"> */}
            <Paper square>
                <Tabs value={value} onChange={handleChange} aria-label="disabled tabs example"
                    indicatorColor="primary"
                    textColor="primary">
                    <Tab label="General" {...a11yProps(0)} />
                    {/* <Tab label="Templates" {...a11yProps(1)} /> */}
                    {/* <Tab label="Promotions" {...a11yProps(2)} /> */}
                </Tabs>
            </Paper>
            {/* </AppBar> */}
            <TabPanel value={value} index={0}>
                <GeneralSection rowData={rowData} setRowData={setRowData} updateRowInRowData={props.updateRowInRowData} agentList={props.agentList} leadType={props.leadType} postLead={props.postLead} deleteLead={props.deleteLead} tableRef={props.tableRef} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                Templates coming soon
                {/* <MesssageSection rowData={rowData} setRowData={setRowData} agentList={props.agentList} leadType={props.leadType} /> */}
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
        </TabPanel>
        </div>
    );
}

const Leads = (props) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };




    const [data, setData] = React.useState([
        {
            id: 1,
            name: "Amulya Parmar TYG",
            email: "parmar.amulya@gmail.com",
            leadType: [0],
            agent: 0,
            number: "123321123",
            notes: "needed 3bed",
            tourBookedFor: " 406 E. Green St, 3 Bedroom",
            tourBookedOn: " October 15, 2020 3 PM",
            questionAsked: "",
            leadStatus: "7 Day Followup Needed",
            followups: [
                {
                    title: "Same Day Followup",
                    days: 0,
                    isComplete: true,
                },
                {
                    title: "3 Day Followup",
                    days: 3,
                    isComplete: false,
                },
                {
                    title: "5 Day Followup",
                    days: 5,
                    isComplete: false,
                },
                {
                    title: "7 Day Followup",
                    days: 7,
                    isComplete: false,
                }
            ],
            hasApplied: false,
            notInterested: false,

            updated_on: "25 September 2020",
            created_on: "Mon, 01 Oct 2020 06:35:56 GMT",
        },
    ])

    /*

                {Same-Day Followup
                3 Day Followup
                7 Day Followup
                14 Day Folllowup
                30 Day Folllowup }

        id = db.Column(db.Integer, primary_key = True, autoincrement = True)
        name = db.Column(db.String(200), default="")
        email = db.Column(db.String(200), default="")
        number = db.Column(db.String(50), default="")
        notes = db.Column(db.String(20000), default="")

        leadType = db.Column(db.String(200), default="")
        agent = db.Column(db.String(200), default="")

        questionAsked = db.Column(db.String(2000), default="")
        tourBookedOn = db.Column(db.String(2000), default="")
        tourBookedFor = db.Column(db.String(2000), default="")

        followups = db.Column(db.String, db.String(2000),default="")

        notInterested = db.Column(db.Boolean, default=False)
        hasApplied = db.Column(db.Boolean, default=False)

        last_updated = db.Column(db.DateTime, default=datetime.utcnow)
        created = db.Column(db.DateTime, default=datetime.utcnow)

    */

    const agentList = [
        //Kierra, Ashley, Ron Bufkin, Savannah Meneley, Ryan Marde
        { name: 'Ashley Matheny', value: 0 },
        { name: 'Kierra Morris', value: 1 },
        { name: 'Ron Bufkin', value: 2 },
        { name: 'Savannah Meneley', value: 3 },
        { name: 'Ryan Marde', value: 4 },
        { name: 'Agent #1', value: 5 },
        { name: 'Agent #2', value: 6 },
        { name: 'Agent #3', value: 7 },
        { name: 'Agent #4', value: 8 },
        { name: 'Agent #5', value: 9 },
    ];

    const leadType = [
        { name: 'Booked Tour', value: 0 },
        { name: 'Ask a Question', value: 1 },
        { name: 'In-Person', value: 2 },
        { name: 'Other', value: 3 },
        { name: 'Applied for Lease', value: 4 },
        { name: 'Referral', value: 5 },
    ];

    const tableRef = useRef({
        onQueryChange: () => {
        }
    });

    const [leadLength, setleadLength] = useState({ "total": 1, "week": 1 })
    const [followupsNeeded, setFollowupsNeeded] = useState({ "total": 1, "week": 1 })
    const [leaseCount, setLeaseCount] = useState({ "total": 1, "week": 1 })



    function checkIfLeadHasFollowups(lead) {


        let createTimestamp = Date.parse(lead.created_on);

        var date1 = moment(createTimestamp);
        let today = moment(Date.now());

        var leadObj = lead.followups.filter((followup) => {
            return (today.diff(date1, "days") >= followup.days) && followup.isComplete === false;
        })

        return leadObj;
    }

    async function updateLeadStats(filteredData) {
        var leaseCt = 0;
        var leaseCtThisWk = 0;

        var followupCt = 0;
        var followupCtFromPrevWk = 0;

        var leadCt = filteredData.length;
        var leadCtThisWk = 0;

        let today = moment(Date.now())
        function foundThisWk(dateStr) {
            return today.diff(moment(Date.parse(dateStr)), "days") <= 7;
        }

        filteredData.forEach((lead) => {

            if (foundThisWk(lead.created_on)) {
                leadCtThisWk = leadCtThisWk + 1;
            }

            if (lead.hasApplied === true) {
                leaseCt = leaseCt + 1;
                if (foundThisWk(lead.updated_on)) {
                    leaseCtThisWk = leaseCtThisWk + 1;
                }
            }

            else {
                var followupCtForLead = checkIfLeadHasFollowups(lead);
                if (followupCtForLead.length > 0 && (!lead.notInterested && !lead.hasApplied)) {
                    followupCt = followupCt + followupCtForLead.length;
                    if (!foundThisWk(lead.updated_on)) {
                        followupCtFromPrevWk = followupCtFromPrevWk + 1;
                    }
                }
            }
        });

        setleadLength({ "total": leadCt, "week": leadCtThisWk });
        setLeaseCount({ "total": leaseCt, "week": leaseCtThisWk });
        setFollowupsNeeded({ "total": followupCt, "week": followupCtFromPrevWk });
        console.log(leaseCt, followupCt);

    }


    // design of the GET http request for querying leads 
    let requestOpts = { "host": "https://bankier-leads-backend2.herokuapp.com" }
    // requestOpts = { "host": "http://localhost:8080" }
    const [loading, error, queryData, makeRequest] = useRequest({ loading: true });


    // Update Backend and Local state
    function postLead(id, leadData) {
        post("/lead?id=" + id, {
            ...leadData,
        });
    }

    async function addLead(leadData) {
        await post("/lead", {
            ...leadData,
        });
        getLeads();
    }

    // Update Backend and Local state
    async function deleteLead(id) {
        await deleteReq("/lead?id=" + id);
        getLeads();
    }

    const getLeads = async () => {
        const res = await makeRequest('/leads', "GET", null, requestOpts);
        console.log("result leads: ", res.leads);
        setData(res.leads)
    }

    function updateRowInRowData(rowData) {
        var idx = -1;
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === rowData.id) {
                idx = i;
            }
        }
        if (idx === -1) {
            var updatedRows = { ...data, rowData };
            setData(updatedRows);
        } else {
            var updatedRows = data;
            updatedRows[idx] = rowData;
            setData(updatedRows);
        }
    }



    const [filterNeedFollowup, setFilterNeedFollowup] = useState(false)
    const [filterHasLeased, setFilterHasLeased] = useState(false)
    const filterValue = value => {
        if (value) {
            const filtered = data.filter(row => {
                console.log("lets filter: ", row, checkIfLeadHasFollowups(row));
                return (checkIfLeadHasFollowups(row).length > 0) && (!row.notInterested && !row.hasApplied);
            });
            setData(filtered);
        } else {
            getLeads();
        }
        setFilterNeedFollowup(value);
    };
    const filterLeased = value => {
        if (value) {
            const filtered = data.filter(row => {
                // console.log("lets filter: ", row, checkIfLeadHasFollowups(row));
                return (row.hasApplied === true);
            });
            setData(filtered);
        } else {
            getLeads();
        }
        setFilterHasLeased(value);
    };

    useEffect(() => {
        getLeads();
    }, [])

    useEffect(() => {
        updateLeadStats(data);
    }, [data])


    /*

        // if ctrl s is clicked
        if ((window.navigator.platform.match("Mac") ? event.metaKey : event.ctrlKey)  && event.keyCode == 83) {
            event.preventDefault();
            // Process the event here (such as click on submit button)
            handleSaveAlert();
        }

    */

    document.addEventListener("keydown", function (e) {
        if ((window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) && e.keyCode == 83) {
            e.preventDefault();
            // Process the event here (such as click on submit button)
            document.getElementById('addNewLeadTYGButton').click();
        }
    }, false);

    function pluralize(string, count) {
        if (count == 1) {
            return string;
        } else {
            return string + "s";
        }
    }

    function getAgent(agentIdx, nullReplacement = "") {
        if (agentIdx === null) return nullReplacement;
        if (agentIdx < 0 || agentIdx >= agentList.length) return nullReplacement;
        else {
            return agentList[agentIdx].name;
        }
    }

    return (
        <div className={classes.root}>

            <center>
                <Grid container spacing={3} style={{ margin: "15px" }}>
                    <Grid container item xs={4}  >
                        <StampCard
                            color="blue"
                            icon="users"
                            header={
                                <a href="#">
                                    {leadLength.total} <small>Leads </small> <br />
                                </a>
                            }
                            footer={leadLength.week + " " + pluralize("Lead", leadLength.week) + " captured this week"}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <StampCard
                            color="red"
                            icon="message-square"
                            header={
                                <a href="#">
                                    {followupsNeeded.total} <small>Followups Needed</small>
                                </a>
                            }
                            footer={followupsNeeded.week + " " + pluralize("Followup", followupsNeeded.week) + " from previous weeks"}
                        />
                    </Grid>
                    <Grid container item xs={4} >
                        <StampCard
                            color="green"
                            icon="shopping-cart"
                            header={
                                <a href="#">
                                    {leaseCount.total} <small>Lease Applications</small>
                                </a>
                            }
                            footer={leaseCount.week + " " + pluralize("Lease", leaseCount.week) + " signed this week"}
                        />
                    </Grid>
                </Grid>
            </center>

            <br />

            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image="/static/images/cards/contemplative-reptile.jpg"
                        title="Contemplative Reptile"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Bankier, welcome to your dashboard
          </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            The singular source of truth for all your leads,  traffic, and more.
          </Typography>
                    </CardContent>
                </CardActionArea>

            </Card>


            <ContentLoader loading={loading} error={error}>

                <MaterialTable
                    //https://stackoverflow.com/questions/60009133/how-to-get-reactjs-material-table-search-results-count
                    tableRef={tableRef}
                    localization={{ toolbar: { searchPlaceholder: 'Search w/Stats update: Agent' } }}

                    onSearchChange={() => {

                        updateLeadStats(tableRef.current.state.data)
                    }}
                    // onFilterChange={() => {

                    //     updateLeadStats(tableRef.current.state.data)
                    // }}
                    icons={tableIcons}
                    options={{
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                        },
                        rowStyle: {
                            backgroundColor: '#EEE',
                        },
                        filtering: true,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 20, { value: data.length, label: 'All' }],
                    }}
                    columns={[
                        {
                            title: "Name", field: "name", editComponent: editProps => (
                                <Input
                                    autoFocus={true}
                                    onChange={e => editProps.onChange(e.target.value)}
                                />
                            )
                        },
                        { title: "Email", field: "email" },
                        {
                            title: "Agent", field: "agent", render: rowData => {
                                return (
                                    <>

                                        <Grid container spacing={3}>
                                            <Grid item sm={3}>
                                                <Avatar> {getAgent(rowData.agent).split(" ").map((n) => n[0]).join("").split(0, 2)} </Avatar>
                                                {/* <Avatar>{(rowData.agent === null) ? " " : agentList[rowData.agent].name.split(" ").map((n) => n[0]).join("").split(0, 2)}</Avatar> */}
                                            </Grid>
                                            <Grid item sm={9} style={{ "margin": "auto" }}>{getAgent(rowData.agent, "Unassigned")}</Grid>
                                        </Grid>

                                    </>
                                );
                            },
                            customFilterAndSearch: (term, localRowData) => {
                                return getAgent(localRowData.agent, "unassigned").toLowerCase().indexOf(term.toLowerCase()) !== -1;
                            },
                            editable: 'never',
                        },
                        {
                            title: "Property Tour / Question",
                            field: "surname",
                            cellStyle: {
                                maxWidth: 30
                            },
                            render: rowData => {
                                var questionAsked = "questionAsked" in rowData && rowData.questionAsked.length > 1
                                var tourBooked = "tourBookedFor" in rowData && "tourBookedOn" in rowData && rowData.tourBookedFor.length > 1 && rowData.tourBookedOn.length > 1
                                return (
                                    <>
                                        {questionAsked ?
                                            <span> Question asked: {(rowData.questionAsked.length > 15) ? '"' + rowData.questionAsked.substr(0, 16) + '"...' : '"' + rowData.questionAsked + '"'} </span>
                                            :
                                            <span></span>
                                        }
                                        {questionAsked && tourBooked && <span> | </span>}
                                        {tourBooked ?
                                            <span style={{ width: "100px" }}> Booked Tour For: "{(rowData.tourBookedFor.length > 26) ? rowData.tourBookedFor.substr(0, 26) + '"...' : '"' + rowData.tourBookedFor + '"'}  on {(rowData.tourBookedOn.length > 25) ? rowData.tourBookedOn.substr(0, 26) + '...' : rowData.tourBookedOn} </span>
                                            :
                                            <span></span>
                                        }
                                    </>
                                )
                            },
                            customFilterAndSearch: (term, localRowData) => {
                                return (localRowData.questionAsked + " " + localRowData.tourBookedOn + " " + localRowData.tourBookedFor).toLowerCase().indexOf(term.toLowerCase()) !== -1
                            },
                            editable: 'never',


                        },

                        // {
                        //     title: "Date Created", field: "created_on", render: rowData => {
                        //         let createTimestamp = Date.parse(rowData.created_on);
                        //         // createTimestamp.toLocaleFormat('%d-%b-%Y');

                        //         return <Badge color="secondary" >{moment(createTimestamp).format('MMMM Do YYYY, h:mm:ss a')}</Badge>
                        //     },
                        //     customSort: (a, b) => moment(Date.parse(a)).diff(moment(Date.parse(b))),
                        //     editable: 'never',
                        // },
                        {
                            title: "Date Updated", field: "updated_on", render: rowData => {
                                let updateTimestamp = Date.parse(rowData.updated_on);
                                // createTimestamp.toLocaleFormat('%d-%b-%Y');
                                return <Badge color="info" >{moment(updateTimestamp).format('MMMM Do YYYY, h:mm:ss a')}</Badge>
                            },
                            customSort: (a, b) => {
                                return moment(Date.parse(a.updated_on)).diff(moment(Date.parse(b.updated_on)));
                            },
                            defaultSort: 'desc',
                            editable: 'never',
                        },
                        {
                            title: "Lead Type",
                            field: "leadType",
                            render: rowData => {
                                return (
                                    <>
                                        {(rowData["leadType"] !== null) && rowData.leadType.map((item, idx) => {
                                            return <Badge color="primary"> {leadType[item].name}  &nbsp;</Badge>
                                        })}
                                    </>
                                )
                            },
                            customFilterAndSearch: (term, localRowData) => {
                                return localRowData.leadType.some((leadIdx) => {
                                    return leadType[leadIdx].name.toLowerCase().indexOf(term.toLowerCase()) !== -1
                                }
                                )
                            },
                            editable: 'never',

                        },
                        // {
                        //     field: 'isLeased',
                        //     title: 'Leased',
                        //     render: rowData => {
                        //         return (
                        //             <Checkbox checked={rowData.isLeased} onClick={rowData.isChecked} />
                        //         );
                        //     }
                        // },
                        {
                            title: "Lead Status", field: "leadStatus", render: rowData => {
                                var hasApplied = ("hasApplied" in rowData && rowData.hasApplied === true)
                                var notInterested = ("notInterested" in rowData && rowData.notInterested === true)
                                return (
                                    <>
                                        {hasApplied && <Badge color="success" className="mr-1">
                                            Applied
                                    </Badge>}
                                        {(notInterested && !hasApplied) && <Badge color="secondary" className="mr-1">
                                            Not Interested
                                    </Badge>}
                                        {(!hasApplied && !notInterested) && ("followups" in rowData) &&
                                            checkIfLeadHasFollowups(rowData).map((followup) => {
                                                return (
                                                    <>
                                                        < Badge color="danger" className="mr-1">
                                                            {followup.title}
                                                        </Badge>
                                                    </>
                                                )
                                            })

                                        }


                                    </>
                                );
                            },
                            filterComponent: props => {
                                return (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={filterNeedFollowup}
                                                    color="primary"
                                                    onChange={e => filterValue(e.target.checked)}
                                                />
                                            }
                                            label="Need Followup"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={filterHasLeased}
                                                    color="primary"
                                                    onChange={e => filterLeased(e.target.checked)}
                                                />
                                            }
                                            label="Leased"
                                            labelPlacement="end"
                                        />
                                    </>
                                );
                            },
                            editable: 'never',

                        },
                    ]}
                    // components={{
                    //     Row: props => (
                    //         <MTableBodyRow
                    //             {...props}
                    //         // onDoubleClick={e => {
                    //         //     console.log(props.actions);
                    //         //     props.actions[1]().onClick(e, props.data);
                    //         // }}
                    //         />
                    //     )
                    // }}

                    // components={{
                    //     Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                    //         props.onFilterChanged(columnId, value);
                    //         // Do you job here :)
                    //         updateLeadStats(tableRef.current.state.data);
                    //     }} />
                    // }}

                    editable={{
                        onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    /* setData([...data, newData]); */
                                    addLead({ "name": newData.name, "email": newData.email })
                                    resolve();
                                }, 1000);
                            }),
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                resolve();
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    /* setData([...data, newData]); */
                                    deleteLead(oldData.id)
                                    resolve();
                                }, 1000);
                                resolve();
                            })
                    }}
                    data={data}
                    //https://github.com/mbrn/material-table/issues/978
                    // data={async (query) => {
                    //     console.log("query: ", query);
                    //     let leads = data.length > 1 ? data : await getLeads();

                    //     return {
                    //         data: data,
                    //     }
                    // }}

                    title={<><Button color="primary" variant="outlined" id="addNewLeadTYGButton" onClick={() => {
                        document.querySelector("[keyboard-shortcut='add-icon-handler']").parentNode.click();
                    }}>+ Add new Lead</Button></>}
                    detailPanel={rowData => {
                        return (
                            <LeadActionPanel rowData={rowData} agentList={agentList} leadType={leadType} postLead={postLead} deleteLead={deleteLead} updateRowInRowData={updateRowInRowData} tableRef={tableRef} />
                        )
                    }}
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />

            </ContentLoader>
            <Button onClick={() => {
                console.log('tableRef', tableRef)
                updateLeadStats(tableRef.current.state.data);
            }

            }>
                Fetch Stats for this Query
            </Button>

            <br />
            <br />
            <br />
            <br />
            <br />
            <center>Built with ❤️& lots of ☕ for Bankier Apartments // LeaseMagnets™ </center>
        </div >
    );
}

export default Leads;